<template>
  <div class="mt-5 mb-5">
    <template>
      <b-container>
        <h1 class="py-1">Terms of Service for – MedAsk Tours Website</h1>
        <h2 class="">Acceptance of Terms</h2>
        <p>
          By accessing or utilizing the MedAskTours Website, you hereby
          acknowledge and consent to adhere to these legally binding Terms of
          Service. In the event that you do not concur with any stipulations
          delineated herein, kindly refrain from opting to avail yourself of any
          services proffered through this Website.
        </p>

        <h2>Medical Tourism Services</h2>
        <p>
          The MedAskTours Website serves as a facilitation platform enabling
          users to obtain information pertaining to medical services,
          facilities, and associated travel services. We act as an intermediary
          connecting users with healthcare providers and facilitating the
          coordination of arrangements for medical tourism.
        </p>

        <h2>User Eligibility</h2>
        <p>
          To avail yourself of our services, it is imperative that you attain
          the age of at least 18 years and possess the legal capacity to enter
          into a binding agreement. Your utilization of the website serves as
          confirmation that you satisfy these eligibility criteria.
        </p>

        <h2>User Obligations</h2>
        <p>
          It is incumbent upon you to furnish accurate and current information
          during the utilization of our services. Furthermore, you expressly
          undertake not to employ the MedAskTours Website for any unlawful or
          unauthorized purposes. In addition, you shall refrain from disrupting
          the regular operation of the website or making any unauthorized
          attempts to access any of its components.
        </p>
        <p>
          In conjunction with the previously outlined user responsibilities,
          users further agree to adhere to the following obligations when
          accessing and utilizing the MedAskTours Website:
        </p>
        <ul>
          <li>
            <strong>Confidentiality:</strong>
            Users shall exercise due diligence in maintaining the
            confidentiality of any account credentials or access information
            associated with the website, and shall not disclose such information
            to unauthorized third parties.
          </li>
          <li>
            <strong>Lawful Conduct:</strong>
            Users shall engage in lawful and ethical conduct while using the
            website, refraining from any activities that violate applicable laws
            or regulations.
          </li>
          <li>
            <strong>Respect for Third-Party Rights:</strong>
            Users shall respect the intellectual property rights, privacy, and
            other legal rights of third parties when interacting with the
            website and shall not engage in any activities that infringe upon or
            violate such rights.
          </li>
          <li>
            <strong>Prompt Reporting of Issues:</strong>
            Users shall promptly report any issues, malfunctions, or security
            vulnerabilities observed on the website to MedAsk Tours to
            facilitate timely resolution and enhance the overall security and
            functionality of the platform.
          </li>
          <li>
            <strong>Compliance with Policies:</strong>
            Users shall comply with all additional policies, guidelines, and
            terms set forth by MedAsk Tours, as may be communicated from time to
            time, to ensure the proper and lawful use of the website.
          </li>
          <li>
            <strong>Professionalism in Interactions:</strong>
            Users engaging with healthcare providers through the platform shall
            conduct themselves in a professional and respectful manner,
            recognizing the sensitivity of medical information and the
            importance of maintaining a positive user experience for all parties
            involved.
          </li>
        </ul>
        <p>
          By accessing and using the MedAsk Tours Website, users explicitly
          acknowledge and accept these additional obligations as integral
          components of their responsibilities on the platform. Failure to
          comply with these obligations may result in appropriate actions,
          including but not limited to the suspension or termination of user
          access.
        </p>

        <h2>Booking and Payments</h2>
        <p>
          <strong>a.</strong> Bookings for all medical services are subject to
          availability.
        </p>
        <p>
          <strong>b.</strong> Payments for medical services must be made in
          accordance with the terms specified by your independent healthcare
          provider.
        </p>

        <h2>Privacy Policy</h2>
        <p>
          Through the utilization of our services, you expressly consent to and
          accept the terms delineated in our Privacy Policy. This policy
          comprehensively elucidates the methodologies employed in the
          acquisition, utilization, and safeguarding of your personal
          information.
        </p>

        <h2>Intellectual Property</h2>
        <p>
          <strong>a.</strong> All content present on the MedAskTours Website,
          encompassing but not limited to text, graphics, logos, and images,
          constitutes the intellectual property of the MedAskTours Website and
          is safeguarded by applicable prevailing copyright laws.
        </p>
        <p>
          <strong>b.</strong> Without obtaining prior written authorization from
          MedAskTours, you are expressly prohibited from reproducing,
          distributing, or displaying any segment of the website.
        </p>

        <h2>Termination</h2>
        <p>
          MedAsk Tours retains the authority to unilaterally terminate or
          suspend your access to the website at its discretion, and such actions
          may be effected at any time and for any reason, without prior notice.
        </p>

        <h2>Changes to Terms of Service</h2>
        <p>
          MedAskTours reserves the prerogative to periodically revise these
          Terms of Service. Users shall receive notification of material
          modifications, and the persistent utilization of the website shall be
          construed as an explicit acceptance of the amended terms.
        </p>

        <h2>Governing Law</h2>
        <p>These Terms of Service are governed by the laws of Pakistan.</p>

        <h2>Contact Information</h2>
        <p>MedAsk Tours</p>
        <p>+92-51-111-633-275</p>
      </b-container>
    </template>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
h1 {
  font-weight: bolder;
  color: #091e3d;
}
h2 {
  font-weight: bold;
  color: black;
}
p {
  font-weight: normal;
  color: #525252;
}
strong {
  font-weight: bold;
  color: #06bd46;
}
</style>
